const { track } = require("./analytics-helpers");

(function init_page_feedback() {
  let yesButton = document.getElementById("page-is-useful");
  let noButton = document.getElementById("page-is-not-useful");
  let successBanner = document.querySelector(".js-prompt-success");
  let promptQuestion = document.querySelector(".js-prompt-questions");

  const registerClick = function (event, value) {
    event.preventDefault();
    if (successBanner) {
      successBanner.removeAttribute("hidden");
    }
    if (promptQuestion) {
      promptQuestion.setAttribute("hidden", true);
    }
    track("page_is_useful", value);
  };

  if (yesButton) {
    yesButton.addEventListener("click", (event) => registerClick(event, "YES"));
  }

  if (noButton) {
    noButton.addEventListener("click", (event) => registerClick(event, "NO"));
  }
})();
